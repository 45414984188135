import React from 'react';
import Layout from '../layouts/Layout';
import I18n from '../utils/I18n';

const HomeTemplate = data => {
  const { pageContext: { lang } } = data;
  const i18n = new I18n({ lang });
  const iosLogo = `https://developer.salesforce.com/resources2/trailhead-go/Appstore_${lang}.svg`
  const iosLogoQR = 'https://developer.salesforce.com/resources2/trailhead-go/apple-store-qr@x2.png?v=2'

  const androidLogo = `https://developer.salesforce.com/resources2/trailhead-go/playstore_${lang}.png`
  const androidLogoQR = 'https://developer.salesforce.com/resources2/trailhead-go/google-play-qr@x2.png?v=2'

  return (
    <Layout lang={lang}>
      <div>
        <tm-page class="theme--developer">
          <div className="heroGradient">
            <tm-hero-a 
              background="none" 
              id="hero-trailhead-go" 
              img-align="bottom" 
              img-src="https://developer.salesforce.com/resources2/trailhead-go/trailhead-go-ios-android.png" 
              img-alt={i18n.text('hero_img_alt')} >
              
              <div class="download-links" slot="cta">
                <div className="slds-grid slds-wrap hero-logo-container">
                  <img src="https://developer.salesforce.com/resources2/trailhead-go/trailhead-go-app-icon@x2.png" alt={''} />
                  <h1 className="tds-title">{i18n.text('hero_title')}</h1>
                </div>

                <p className="tds-subtitle slds-p-top_medium">{i18n.text('hero_subtitle')}</p>

                <div className="Fw(700) slds-p-top_medium slds-p-bottom_medium">{i18n.text('hero_cta_title')}</div>
                
                <div class="custom-hide_large">
                  <a 
                    data-label={i18n.text('hero_cta_ios')} 
                    href={i18n.text('hero_cta_ios_href')} 
                    target="_blank"
                    className="slds-p-right_small">

                    <img alt={i18n.text('hero_cta_ios_img_alt')} src={iosLogo} style={{height: '40px'}} />
                  </a>

                  <a 
                    data-label={i18n.text('hero_cta_android')}
                    href={i18n.text('hero_cta_android_href')}
                    target="_blank">

                    <img alt={i18n.text('hero_cta_android_img_alt')} src={androidLogo} style={{height: '40px'}} />
                  </a>
                </div>

                <div class="custom-show_large desktop-platform-logos">
                  <a 
                    data-label={i18n.text('hero_cta_ios')} 
                    href={i18n.text('hero_cta_ios_href')} 
                    target="_blank"
                    className="slds-p-right_medium">

                    <img alt={i18n.text('hero_cta_ios_img_alt')} src={iosLogoQR} />
                  </a>

                  <a 
                    data-label={i18n.text('hero_cta_android')}
                    href={i18n.text('hero_cta_android_href')}
                    target="_blank">

                    <img alt={i18n.text('hero_cta_android_img_alt')} src={androidLogoQR} />
                  </a>
                </div>
                
              </div>
            </tm-hero-a>
          </div>

          <tm-section-a background="white" title={i18n.text('section1_title')}>
            <tm-feature-grid-a> 
              <tm-grid-item></tm-grid-item>
              <tm-grid-item 
                alt={i18n.text('section1_item1_img_alt')} 
                description={i18n.text('section1_item1_img_description')} 
                img-src="https://developer.salesforce.com/resources2/trailhead-go/trailhead-go-learning-modules.png" 
                title={i18n.text('section1_item1_title')}
                img-alt={i18n.text('section1_item1_img_alt')} 
              />

              <tm-grid-item 
                alt={i18n.text('section1_item2_img_alt')} 
                description={i18n.text('section1_item2_img_description')} 
                img-src="https://developer.salesforce.com/resources2/trailhead-go/trailhead-go-trailblazer-community.png" 
                title={i18n.text('section1_item2_title')}
                img-alt={i18n.text('section1_item2_img_alt')} 
              />

              <tm-grid-item 
                alt={i18n.text('section1_item3_img_alt')} 
                description={i18n.text('section1_item3_img_description')} 
                img-src="https://developer.salesforce.com/resources2/trailhead-go/trailhead-go-recommended-learning-my-favorites.png" 
                title={i18n.text('section1_item3_title')}
                img-alt={i18n.text('section1_item3_img_alt')} 
              />
            </tm-feature-grid-a> 
          </tm-section-a> 
        
          <tm-end-cap-a 
            background="light" 
            img-src="https://developer.salesforce.com/resources2/trailhead-go/trailhead-go-app-icon@x2.png" 
            subtitle={i18n.text('endcap_subtitle')} 
            title={i18n.text('endcap_title')}
            img-alt={''}
          />
        </tm-page>

        <p>
          <th-element-loader element="tm-page" style={{height: '100vh'}}></th-element-loader>
        </p>

      </div>
    </Layout>
  );
};

export default HomeTemplate;
